
import { mapState } from 'vuex'
export default {
    name: 'Index',
    computed: {
        ...mapState({
            cms: (state) => state.cms.cms,
            isHomeComponentLoaded: (state) => state.cms.isHomeComponentLoaded,
            isFooterComponentLoaded: (state) =>
                state.cms.isFooterComponentLoaded,
        }),
    },
    mounted() {
        // Call this store action only when the user is on the home page
        if (this.isMobileOnly() && !this.isHomeComponentLoaded) {
            this.$store.dispatch('cms/homeCMS', { platform: 2 })
        }

        if (!this.isMobileOnly() && !this.isHomeComponentLoaded) {
            this.$store.dispatch('cms/homeCMS', { platform: 1 })
        }
        if (!this.isMobileOnly() && !this.isFooterComponentLoaded) {
            this.$store.dispatch('cms/footerCMS', { platform: 1 })
        }
    },
}
